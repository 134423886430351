#health {
  background-color: rgb(255, 255, 255);
  color: black;
  /**box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  overflow: hidden;
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 3rem; */
  margin: auto;
  margin-bottom: 3rem;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.healthIntro {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  text-align: center;
}

.healthIntro > h2 {
  text-align: center;
  font-family: "Recoleta";
  font-size: 2rem;
  margin: auto;
}

.healthBenefitsText {
  text-align: center;
}

.healthBenefits {
  display: flex; /* Use flexbox to align child elements side by side */
  margin: 1rem 3rem;
}

.healthIntro,
.firstBenefit,
.secondBenefit,
.thirdBenefit,
.fourthBenefit {
  flex: 1; /* Allow child elements to grow and occupy equal space */
  text-align: left; /* Center the content inside each div */
  margin-left: 10px; /* Add some spacing between the divs */
  margin-right: 10px; /* Add some spacing between the divs */
}

.firstBenefit,
.secondBenefit,
.thirdBenefit,
.fourthBenefit {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.firstBenefitTitle,
.secondBenefitTitle,
.thirdBenefitTitle {
  font-family: "Recoleta";
  font-size: 1.3rem;
  padding: 0.6rem;
}

.firstBenefit:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 128, 128, 0.2);
  background: #a020f0;
  color: white;
  cursor: pointer;
}

.secondBenefit:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 128, 128, 0.2);
  cursor: pointer;
  background: #a020f0;
  color: white;
}

.thirdBenefit:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 128, 128, 0.2);
  cursor: pointer;
  background: #a020f0;
  color: white;
}

.firstBenefitContainer,
.secondBenefitContainer,
.thirdBenefitContainer {
  padding: 1px 16px;
}

.firstBenefitImg,
.secondBenefitImg,
.thirdBenefitImg,
.fourthBenefitImg {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.firstBenefitText,
.secondBenefitText,
.thirdBenefitText,
.fourthBenefitText {
  max-width: 75rem;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 0.9rem;
}

/* .healthBenefitsTitle {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 4px;
} */

.healthBenefitsText {
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: left;
}

.healthBtn {
  background-color: #a020f0;
  color: black;
  margin: 1rem;
  padding: 0.7rem 1.3rem;
  border: none;
  border-radius: 25px;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: medium;
}

.healthBtn > a {
  color: #ffff;
  text-decoration: none;
}

.healthBtn > a:visited {
  color: #ffff;
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta"), url(../../fonts/Recoleta-SemiBold.woff) format("woff");
}

@media screen and (max-width: 720px) {
  .healthBenefits {
    flex-direction: column;
  }

  .firstBenefit,
  .secondBenefit,
  .thirdBenefit {
    margin: 1rem 0;
    width: 90vw;
    height: auto;
    border: 1px solid #dfb9f7;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
  }

  .firstBenefitImg,
  .secondBenefitImg,
  .thirdBenefitImg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .firstBenefitContainer {
    width: 100%;
    height: auto;
  }
  

  .healthIntro {
    width: 100%;
    padding: 1rem;
  }

  .healthIntro > h2 {
    font-size: 1.7rem;
  }
}
