.dashboard-container {
  display: flex;
  flex-direction: column; /* Main container is a column */
  height: 100vh;
}

/* Styles for top navigation */
.top_nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 196, 2);
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ccc;
}

.mobMenu {
  display: none;
}

.outer__content {
  display: flex;
  flex-grow: 1; /* Content area will take remaining space */
}

.menu__bars {
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.nav-menu {
  background-color: #060b26;
  width: 250px;
  height: calc(100vh - 80px);
  top: 80px; /* Height of the top nav */
  float: left;
  overflow-y: auto; /* Add scrollbar for long sidebar content */
  z-index: 1; /* Ensure sidebar is above other content */
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 8px 8px 16px;
  list-style-type: none;
  margin: 1rem 0;
}

.logout__div,
.nav-text a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  padding: 8px 16px;
}

.logout__div:hover {
  background-color: #1a83ff;
  cursor: pointer;
}
.nav-text a:hover {
  background-color: #1a83ff;
  cursor: pointer;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.content {
  flex-grow: 1; /* Content will take remaining space */
  padding: 20px;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.userGreet {
  color: black;
  font-size: medium;
  text-align: center;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: auto;
}

.profileImgDiv {
  display: flex;
  align-items: center;
}

.userProfileInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: black;
}

.userProfileInfo > p {
  margin-left: 20px;
}

.userInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.userAccountCircleIcon {
  height: 40px !important;
  width: 40px !important;
  color: #060b26;
  cursor: pointer;
}

.menuSIdeBarDiv {
  display: flex;
  flex-direction: column;
}

.userNotificationIcon {
  height: 30px !important;
  width: 30px !important;
  color: #060b26;
  cursor: pointer;
}

.profileDropdown {
  display: none; /* Initially, hide the dropdown */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3b2020;
  box-shadow: 3px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.profileDropdown > p {
  color: black;
}

.accountIconDiv:hover .profileDropdown {
  display: block;
}

@media screen and (max-width: 768px) {
  .menu__bars {
    display: block;
    margin-left: 10px;
  }

  .nav-menu {
    display: none;
  }

  .userProfileInfo > p {
    display: none;
  }
  .top_nav {
    width: 100%;
  }

  .mobMenu {
    display: none;
    flex-grow: 1; /* Content area will take remaining space */
    left: -100%;
    position: sticky;
  }

  .mob-menu {
    background-color: #060b26;
    width: 250px;
    height: calc(100vh - 80px);
    margin-top: 20px;
    float: left;
    overflow-y: auto; /* Add scrollbar for long sidebar content */
    z-index: 1;
  }

  .mob-menu-items {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .mobMenu .active {
    display: block;
    left: 0;
    transition: 350ms;
  }
}
