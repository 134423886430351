.login {
  height: auto;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: 500px;
  margin: 3rem auto;
  background: #fff;
  padding-bottom: 30px;
  box-shadow: 3px 4px 8px 3px rgba(0, 0, 0, 0.2);
}

.header {
  color: #7d0dc3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  width: 100%;
  margin-top: 15px;
}

/* #7D0DC3 */
.loginText {
  color: #7d0dc3;
  font-size: 30px;
  font-weight: 700;
}

.underline {
  width: 61px;
  height: 6px;
  background: #7d0dc3;
  border-radius: 9px;
}

.loginInputs {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.loginInput {
  display: flex;
  align-items: center;
  margin: auto;
  width: 430px;
  height: 60px;
  background: #eaeaea;
  border-radius: 6px;
}

.loginInput img {
  margin: 0px 30px;
}

.loginInput input {
  height: 40px;
  width: 300px;
  background: transparent;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
}

.forgot-password {
  padding-left: 62px;
  margin-top: 27px;
  color: #000000;
  font-size: 17px;
}

.forgot-password span {
  color: #7d0dc3;
  cursor: pointer;
}

.submitContainer {
  display: flex;
  gap: 10px;
  margin: 30px auto 0 auto;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 59px;
  color: #fff;
  background: #7d0dc3;
  border: none;
  border-radius: 45px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

/* .register__Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 59px;
  color: #fff;
  background: #7D0DC3;
  border-radius: 45px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
} */

.registrationText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  color: black;
  margin: 1.5rem 3rem;
}

.text-danger {
  color: red;
  margin-left: 2rem;
  margin-top: 2rem;
}

.registrationText p {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.backHomeLink {
  display: flex;
}

@media screen and (max-width: 720px) {
  .login {
    width: 100vw;
    height: auto;
    padding: 2rem;
  }

  .container {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .loginInput {
    width: 90%;
    height: 50px;
  }

  .loginInput img {
    margin: 0px 15px;
  }

  .loginInput input {
    width: 80%;
  }

  .forgot-password {
    padding-left: 10px;
    font-size: 1rem;
  }

  .submitContainer {
    margin: 20px auto 0 auto;
  }

  .submit {
    width: 180px;
    height: 50px;
  }

  .registrationText {
    margin: 1rem 1rem;
    font-size: 1rem;
  }
}
