.newsletter {
  width: 70rem;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0px 140px;
  margin-top: 50px;
  margin-bottom: 150px;
  background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
  gap: 30px;
}

.newsletter h1 {
  margin-top: 50px;
  color: #454545;
  font-size: 35px;
  font-weight: 600;
}

.newsletter p {
  color: #454545;
  font-size: 20px;
}

.newsletter div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  width: 730px;
  height: 70px;
  border-radius: 80px;
  border: 1px solid #e3e3e3;
}

.newsletter input {
  width: 500px;
  margin-left: 30px;
  border: none;
  outline: none;
  color: #616161;
  font-family: "Poppins";
  font-size: 16px;
}

.newsletter div button {
  width: 210px;
  height: 70px;
  border-radius: 80px;
  background: black;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .newsletter {
    width: 100%;
    padding: 0px 20px;
  }

  .newsletter h1 {
    font-size: 25px;
  }

  .newsletter p {
    font-size: 15px;
  }

  .newsletter div {
    width: 100%;
    padding: 0px 20px;
  }

  .newsletter input {
    width: 70%;
  }

  .newsletter div button {
    width: 30%;
  }
}
