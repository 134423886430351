#intro {
  height: auto;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.introContent {
  background-image: url("../../assets/main_bg.webp");
  /* margin-top: "-70px", */
  height: 100vh;
  width: 100%;
  padding-top: 5rem;
  font-size: 2rem;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  background-size: cover; /* Set background size to cover */
  background-repeat: no-repeat; /* Set background repeat to no-repeat */
  padding-bottom: 4rem;
}

.introHead {
  color: gold;
  font-size: 5rem;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: -40px;
  text-align: center;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

.intro__Info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.introText {
  display: flex;
  justify-content: center;
  background-color: rgba(160, 158, 158, 0.356);
  width: 65%;
  margin: auto;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  padding: 1rem;
}

.buttonContainer {
  color: gold;
  display: flex;
  gap: 10px;
  border-radius: 30%;
}

.introImg {
  position: absolute;
  top: 4rem;
  right: 0;
  z-index: -1;
  object-fit: contain;
  height: 60vh;
  max-width: 60%; /* Adjust the size as needed */
  display: flex;
  margin-left: auto;
}

.firstBtn {
  border: none;
  border-radius: 25px;
  background-color: #7d0dc3;
  color: rgb(255, 255, 255);
  padding: 0.7rem 1.3rem;
  font-size: 13px;
  cursor: pointer;
  margin: 2rem 0;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin: 1rem 0;
  /* padding: 0.75rem 1.5rem; */
}

.introBtn {
  border: none;
  border-radius: 25px;
  background-color: #7d0dc3;
  color: rgb(255, 255, 255);
  padding: 0.7rem 1.3rem;
  font-size: 13px;
  cursor: pointer;
  margin: 2rem 0;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin: 1rem 0;
  /* padding: 0.75rem 1.5rem; */
}

.joinText {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0rem 0;
}

.firstBtn > a:visited,
.introBtn > a:visited {
  color: rgb(255, 255, 255);
}

.introBtn > a,
.firstBtn > a {
  color: rgb(255, 255, 255);
}

.join {
  background-color: #fffff0;
  color: black;
  width: 100vw;
  margin: auto;
}

.joinInvitation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8rem 2rem;
  margin: auto;
}

.joinHead {
  color: #7d0dc3;
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.joinInfo,
.joinImgDiv {
  width: 40%;
  justify-content: center;
}

.mob-joinBtn {
  display: none;
}

.joinBtn,
.mob-joinBtn {
  border: none;
  border-radius: 25px;
  background-color: #7d0dc3;
  color: rgb(255, 255, 255);
  padding: 0.7rem 1.3rem;
  font-size: 13px;
  cursor: pointer;
  margin: 2rem 0;
  align-items: center;
  font-family: "Recoleta", sans-serif;
  font-size: medium;
  margin-right: 3rem;
  margin-left: 2rem;
}

.joinBtn:hover,
.mob-joinBtn:hover {
  border-color: #ffd700;
  color: green;
}

.mob-joinBtn > a,
.joinBtn > a {
  color: white;
}

.joinBtn > a:visited,
.mob-joinBtn > a:visited {
  color: white;
}

.joinImg {
  height: 70vh;
  margin-left: 5rem;
  object-fit: contain;
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta"), url(../../fonts/Recoleta-SemiBold.woff) format("woff");
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 841px) and (max-width: 912px) {
  .introContent {
    background-image: url("../../assets/surface_bg.jpg");
    padding-top: 0;
    padding-bottom: 0;
    height: 600px;
    width: 100%;
    margin: 0 auto;
    background-size: cover; /* Set background size to cover */
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 841px) {
  .introContent {
    background-image: url("../../assets/desktop_1.jpg");
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    width: 100%;
    margin: 0 auto;
    background-size: cover; /* Set background size to cover */
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 912px) {
  .introHead {
    font-size: 2.2rem;
    margin: auto;
    text-align: center;
    margin-left: 1rem;
    font-weight: 600;
  }

  .introText {
    font-size: 1.1rem;
    width: 90%;
    text-align: left;
    text-align: justify;
  }

  .buttonContainer {
    margin-left: 0;
  }

  .firstBtn {
    margin: 1rem 0;
  }

  .introBtn {
    margin: 1rem 0;
  }

  .joinInvitation {
    flex-direction: column;
    padding: 0.5rem 0;
  }

  .joinHead {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }

  .joinInfo {
    width: 100%;
    padding: 1rem;
  }

  .joinImgDiv {
    width: 100%;
  }

  .mob-joinBtn {
    display: block;
    margin: 1rem 0;
  }

  .joinBtn {
    margin: 1rem 0;
    display: none;
  }

  .joinImg {
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 1rem;
  }
}
