.payment__details {
  color: black;
  width: 60rem;
  margin: auto;
}

.payment_Links {
  margin-top: 1.5rem;
  margin-bottom: 0.7rem;
  color: black;
}

.payment__steps {
  display: flex;
  flex-direction: row;
  margin: 0.7rem 0;
}

.payment__steps li {
  color: black;
}

.infoIcon {
  margin-right: 2rem;
  color: rgb(5, 144, 224);
}

.paymentForm {
  margin: 2rem 0;
}

.payment__Btn {
  border: none;
  background-color: #a020f0;
  color: rgb(255, 255, 255);
  padding: 0.7rem 1.3rem;
  font-size: 13px;
  cursor: pointer;
  margin: 2rem 0;
  align-items: center;
  font-size: medium;
  margin-right: 3rem;
  margin-left: 2rem;
}
