#privacy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65rem;
  color: black;
  margin: 2rem auto;
  padding: 30px;
}

.summary_para {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.summary_para h2 {
  font-size: 2.5rem;
  margin: 1rem 0;
}

.summary_para p {
  font-size: 1.1rem;
  margin: 1rem 0;
}

.personal_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
}

.personal_details h3 {
  font-size: 2rem;
}

.personal_details p {
  margin-top: 1rem;
  text-align: justify;
  font-size: 1.1rem;
}

.data-collected,
.process-data-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4rem;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.processing-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.processing-data h4 {
  font-size: 1.3rem;
  margin-top: 1rem;
}

.right-to-object {
  margin-top: 1rem;
}

.retaining-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.retaining-data h3 {
  font-size: 2rem;
  margin-top: 1rem;
}

.retaining-data p {
  margin-top: 1rem;
  text-align: justify;
  font-size: 1.1rem;
}

@media screen and (max-width: 912px) {
  #privacy {
    width: 100%;
  }

  .summary_para h2 {
    font-size: 2rem;
  }

  .summary_para p {
    font-size: 1rem;
  }

  .personal_details h3 {
    font-size: 1.5rem;
  }

  .personal_details p {
    font-size: 1rem;
  }

  .data-collected,
  .process-data-list {
    margin-left: 2rem;
    font-size: 1rem;
  }

  .processing-data h4 {
    font-size: 1.1rem;
  }

  .right-to-object {
    font-size: 1rem;
  }

  .retaining-data h3 {
    font-size: 1.5rem;
  }

  .retaining-data p {
    font-size: 1rem;
  }
  
}
