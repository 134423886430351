#contactPage {
  overflow: hidden;
  min-height: calc(100vh - 4rem);
  width: 100%;
  color: black;
  text-align: left;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}

.response-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.response-error-msg {
  background-color: #ffe6e6;
}

.contactForm {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.companyName {
  padding: 5rem;
  background-color: #a020f0;
  color: gold;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.contactHead {
  color: black;
  margin: 1rem 0 0 0;
  font-size: 3rem;
  font-weight: 600;
}

.contactDesc {
  padding: 1rem;
  font-size: medium;
  font-weight: 500;
}

.contacts-name,
.contacts-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  text-align: left;
  font-size: 1.1rem;
}

.contacts-name {
  margin-top: 1.5rem;
}

.contacts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  width: 80%;
  font-size: 1.1rem;
}

.contacts-email,
.contacts-phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  text-align: left;
  margin: 1rem 0;
}

.name,
.email,
.msg,
.address,
.phoneNumber {
  font-size: medium;
  width: 100%;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: black;
  border: none;
  border-radius: 0.5rem;
  background: #e8f0fe;
  font-family: "Poppins", sans-serif;
}

.contact-submitBtn {
  background: #a020f0;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.7rem 2.5rem;
  margin: 1rem;
  font-family: "Poppins", sans-serif;
}

.socialLinks {
  display: flex;
  flex-wrap: wrap;
}

.link {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin: 0 0.5rem;
}

@media screen and (max-width: 1024px) {
  .contactForm {
    width: 100%;
  }

  .contacts {
    flex-direction: column;
    gap: 0;
  }

  .contacts-email,
  .contacts-phone {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contactForm {
    width: 100%;
  }

  .contactForm {
    width: 90%;
  }

  .contacts {
    flex-direction: column;
    gap: 0;
  }

  .contacts-email,
  .contacts-phone {
    width: 100%;
    margin: 10px;
  }
}
