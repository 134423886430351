#landing {
  margin: 2rem;
  color: rgb(173, 70, 70);
}

.dashboard__squares {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: left;
  width: 100w;
  max-width: 65rem;
  flex-wrap: wrap; /* wrap content that exceeds width */
  flex-grow: 1;
  flex-basis: 100px;
  margin-top: 1rem;
  text-align: left;
}

.dashboard__square {
  background-color: #2f4050;
  color: rgb(255, 255, 255);
  font-family: "Recoleta";
  font-size: 1.1rem;
  padding: 1.5rem 2rem;
  margin: 2rem;
  object-fit: cover;
  height: 8rem;
  margin: 1rem;
  width: 15rem;
  align-items: center;
}

.dashboard__square > h4 {
  padding: 0.5rem;
}
