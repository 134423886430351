.relatedProducts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.relatedProducts h1 {
  color: #171717;
  font-size: 50px;
  font-weight: 600;
}

.relatedProducts hr {
  width: 100px;
  height: 6px;
  border-radius: 10px;
  background: #252525;
}

.relatedProductsSlider {
  width: 100%;
  max-width: 1200px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
