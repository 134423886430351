.member__landing {
  display: flexbox;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  color: black;
  padding: 1rem 0rem;
  margin: auto;
}

.welcome__msg {
  font-size: 1.8rem;
  color: #a020f0;
}

.landing__summary {
  background-color: #c47685;
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 1.3rem;
  border: 2px;
  border-radius: 15px;
  color: black;
  padding: 1.5rem;
}

.summary__box {
  background-color: white;
  width: 50%;
  border-radius: 15px;
  padding: 1rem;
  margin-right: 1rem;
  color: black;
}

.payment__status {
  background-color: #a020f0;
  color: white;
  width: 90%;
  margin: 2rem 0;
  border-radius: 15px;
  padding: 0.5rem;
}

.payment__state {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.pay__btn {
  background-color: white;
  color: #a020f0;
  margin: 1rem;
  padding: 0.7rem 1.3rem;
  border: none;
  border-radius: 25px;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
}

.btn__box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.support__btn {
  background-color: #a020f0;
  color: white;
  margin: 1rem;
  padding: 0.7rem 1.3rem;
  border: none;
  border-radius: 25px;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
}

.update__btn {
  background-color: rgb(255, 255, 255);
  color: #a020f0;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border: 2px solid gold;
  border-radius: 20px;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.admin__btn {
  background-color: #a020f0;
  color: rgb(231, 95, 71);
  margin: 1rem;
  padding: 0.7rem 1.3rem;
  border: none;
  border-radius: 25px;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
}

.events__box {
  background-color: white;
  width: 50%;
  border-radius: 15px;
  padding: 1rem;
  margin-right: 1rem;
}

.events__boxBtn {
  background-color: #a020f0;
  color: white;
  margin: 1rem auto;
  padding: 0.7rem 1.3rem;
  border: none;
  border-radius: 25px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
}

@media screen and (max-width: 768px) {
  .member__landing {
    width: 100%;
    padding: 0.5rem;
  }

  .landing__summary {
    flex-direction: column;
  }

  .summary__box {
    width: 100%;
    margin-right: 0;
    padding: 0.5rem;
  }

  .events__box {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
  .events__boxBtn {
    font-size: 1rem;
  }

  .support__btn,
  .admin__btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem auto;
  }

  .landing__summary {
    padding: 0.7rem;
  }

  .btn__box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
