.sixtyAtSixty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: black;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-attachment: scroll;
}

/* .sixty_header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-image: url("../../assets/mary@60/56@56.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  border-radius: 0 0 0 0;
  overflow: hidden;
  margin-top: 0 !important; 
  transform: none !important;
  width: 100%;
  background-attachment: scroll;
} */
.sixty_header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: 0 auto;
  background-image: url("../../assets/mary@60/56@56_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  border-radius: 0 0 0 0;
  overflow: hidden;
  margin-top: 0 !important; /* Ensures it doesn’t shift */
  transform: none !important;
  width: 100%;
  background-attachment: scroll;
  /* position: relative; */
}

.sixty_header_container::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 60vh;
  background: linear-gradient(
    30deg,
    rgba(221, 160, 221, 0.4) 0%,
    rgba(224, 33, 224, 0.4) 35%,
    rgba(106, 13, 173, 0.4) 100%
  );
  z-index: 0;
  border-radius: 0;
  margin: 0 auto;
}

.sixty_header {
  font-size: 3.5rem;
  font-weight: 700;
  color: gold;
  text-align: center;
  margin: 0.5rem auto;
  z-index: 5;
  /* padding-top: 2rem; */
}

.sixty_subheader {
  font-size: 5rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  margin: 0.5rem auto;
  z-index: 5;
}

.disclamer {
  align-items: center;
  background-color: #7608ba;
  width: 100%;
  border-radius: 20px 0 0 0;
  /* right: 0;
  align-self: flex-start;
  margin-top: 35px; */
  /* margin-top: calc(25vh - 100px); */
  padding: 1rem;
  color: #ffffff;
  z-index: 5;
  margin-top: auto;
  text-align: center;
}

.disclaimerSpanDiv {
  width: 90%;
  margin: 0 auto;
  /* align-self: flex-end;
  margin-right: 0;
  padding: 0.5rem; */
}

.disclamer span {
  font-size: 1rem;
  font-weight: 700;
  display: block; /* Ensures full width usage */
}

/* .disclamer > span {
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  margin: 0 auto;
} */

.sixty_header_container p {
  color: #ffffff;
  font-size: larger;
  width: 75%;
  margin: 1rem auto;
  text-align: center;
  top: calc(80vh - 6.5rem);
}

.theme {
  font-size: 2.3rem;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
  color: gold;
}

.headers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  margin: auto;
  margin: 2rem auto;
  max-width: 500px;
}
.intro_para {
  width: 70%;
  margin: 1rem auto;
}

.registrationHeader,
.detailsHeader {
  font-size: 1.4rem;
  font-weight: 700;
  color: #c1acce;
  text-decoration: none;
}

.registrationHeader.active,
.detailsHeader.active {
  color: #7608ba;
  border-bottom: 4px solid #7608ba;
  border: 15%;
}

.registrationHeader:hover,
.detailsHeader:hover {
  cursor: pointer;
  color: #000000;
}

.details_underline,
.register_underline {
  /* width: 61px; */
  height: 6px;
  background: #9879a9;
  border-radius: 9px;
  display: none;
  margin-top: 5px;
  z-index: 5;
}

.details_underline.active,
.register_underline.active {
  /* width: 61px; */
  height: 6px;
  background: #7608ba;
  border-radius: 9px;
}

.registration_form {
  display: none;
}

.registration_form.active {
  display: block;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.event_details {
  display: none;
}

.event_details_info {
  width: 65%;
  margin: 0 auto;
}

.event_details.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 1rem auto;
}

.event_details_header h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #a412ff;
  padding: 1rem;
  text-decoration: none;
}

.event_details_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.event__payment_details h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #a412ff;
  text-decoration: none;
  text-align: center;
  margin: 1rem auto;
}

.registration_form_iframe {
  overflow-y: hidden;
}

.payment__methods h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #a412ff;
  text-decoration: none;
  text-align: center;
  margin: 1rem 0;
}

.payment__methods__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: none;
  gap: 5px;
}

.payment__methods__list li {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  text-align: center;
  gap: 10px;
}

.donation_section h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #a412ff;
  text-decoration: none;
  text-align: left;
  margin: 1rem 0;
}

.event_race_details h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #a412ff;
  text-decoration: none;
  text-align: left;
  margin: 1rem 0;
}

table,
th,
td {
  border: 1px solid;
}

table {
  width: 90%;
  border-collapse: collapse;
  margin: 0 auto;
}

th {
  height: 70px;
}

th {
  color: #7608ba;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:hover {
  background-color: gold;
}

.event_note h4 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #a412ff;
  text-decoration: none;
  text-align: center;
  margin: 1rem auto;
}

.event_note {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.event_note,
.event__location {
  margin: 1rem 0;
}

.event__location h4 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #a412ff;
  text-decoration: none;
  text-align: center;
  margin: 1rem auto;
}

.event_note span {
  margin: 2rem auto;
  font-size: 1.1rem;
  margin-bottom: 20px;
  font-weight: bold;
}
.event__location span {
  margin: 2rem auto;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.map {
  margin-top: 20px;
}

/* .google_form_container {
  width: 100%;
  max-width: 800px; 
  margin: 0 auto;
}

.google-form-container iframe {
  width: 100%;
  height: 100vh;
  border: none;
} */

@media screen and (max-width: 768px) {
  .sixty_header_container {
    height: 40vh;
    min-height: 400px;
    background-image: url("../../assets/mary@60/56@56_mob_bg.png");
    /* background-size: contain; */
  }

  .sixty_header_container::before {
    height: 40vh;
    min-height: 400px;
  }

  .sixty_header {
    margin-top: 20vh;
    font-size: 2.5rem;
  }

  .sixty_subheader {
    font-size: 3rem;
  }

  .sixty_header_container p {
    font-size: 1rem;
  }

  .intro_para {
    width: 90%;
  }

  .headers {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 1rem auto;
  }

  .registration_form_iframe {
    overflow-y: hidden;
  }

  .event_details_info {
    width: 90%;
    margin: 0 auto;
  }

  .disclamer {
    border-radius: 20% 20% 0 0;
  }

  .disclaimerSpanDiv {
    width: 90%;
    text-align: center;
  }

  .registrationHeader:hover,
  .detailsHeader:hover {
    cursor: pointer;
    color: #a412ff;
  }

  .event_note {
    gap: 5px;
    flex-direction: column;
  }
}

@media screen and (max-width: 640px) {
  .detailsHeader {
    font-size: 1.1rem;
  }

  .registrationHeader {
    font-size: 1.1rem;
  }

  table {
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .sixty_header {
    font-size: 2rem;
  }

  .sixty_subheader {
    font-size: 2.5rem;
  }

  .disclamer span {
    font-size: 0.8rem;
  }
}
