.section_padding {
  padding: 4rem 4rem;
}

.footer {
  background-color: #36454f;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.sb__footer {
  display: flex;
  flex-direction: column;
}

.sb__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  text-align: left;
  margin-bottom: 2rem;
}

.sb__footer-links_div {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: #fff;
}

.footer a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.footer a:hover {
  color: gold;
  text-decoration: none;
}

.socialMedia {
  display: flex;
  flex-direction: row;
}

.socialMediaImg {
  object-fit: contain;
  height: 2.5rem;
  margin-top: 0.3rem;
}

.sb__footer-links_div h4 {
  font-size: 1.3rem;
  line-height: 17px;
  margin-bottom: 0.9rem;
}

.sb__footer-links_div p {
  font-size: 14px;
  line-height: 15px;
  margin: 0.5rem 0;
}

.footerEmailStuff {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footerEmailStuff:hover {
  color: gold;
  cursor: pointer;
}

.sb__footer-below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1rem 1rem;
}

.sb__footer-below_links {
  display: flex;
  flex-direction: row;
}

.sb__footer-below_links p {
  font-size: 13px;
  line-height: 15px;
  margin-left: 2rem;
  margin-right: 2rem;
  color: white;
  font-weight: 600;
}

.sb__footer-below_links p:hover {
  color: gold;
}

hr {
  color: white !important;
  width: 100%;
}

.sb__footer-copyright p {
  font-size: 13px;
  line-height: 15px;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

@media screen and (max-width: 850px) {
  .sb__footer-heading h1 {
    font-size: 44px;
    line-height: 50px;
  }

  .section_padding {
    padding: 2rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  .sb__footer-heading h1 {
    font-size: 44px;
    line-height: 50px;
  }

  .sb__footer-links div {
    margin: 1rem 0;
  }

  .sb__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }

  .sb__footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb__footer-below_links {
    flex-direction: column;
  }

  .sb__footer-below_links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .sb__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }
}
