.media__landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  color: black;
  padding: 3rem;
}

.media_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.image_item {
  border: 1px solid #96949455;
  padding: 10px;
  text-align: center;
  width: calc(33.333% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 10px;
}

.mediapage-logo-header {
  font-size: 2rem;
  font-weight: 600;
  align-items: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .media_container {
    display: flex;
    flex-wrap: column;
    gap: 10px;
    justify-content: center;
  }

  .image_item {
    width: calc(100% - 20px);
  }
}

/* .bike-img {
  height: 300px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
}
 */
