.breadcrum {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #3d3333;
  font-size: 18px;
  font-weight: 600;
  margin: 40px 100px;
  text-transform: capitalize;
}

.breadcrumData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.breadcrumCartStuff {
  display: flex;
  flex-direction: row;
  margin-right: 60px;
}

.breadcrumCartCount {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-left: -5px;
  border-radius: 11px;
  font-size: 14px;
  background: red;
  color: white;
}

@media screen and (max-width: 768px) {
  .breadcrum {
    margin: 10px 20px;
  }
  .breadcrumCartStuff {
    margin-right: 20px;
  }
  
}
