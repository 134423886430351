/* Base Styles */
.cartitems_div {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cartitems {
  padding: 20px;
  background-color: #f9f9f9; /* Light ash-grey background */
  color: #4a4a4a; /* Neutral text color */
  font-family: Arial, sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 20px auto;
}

.cartitems hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.cartitems-format-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  margin: 0px auto;
  padding: 20px 0;
  font-weight: bold;
  color: #6d28d9; /* Purple for headings */
}

.cartitems-format {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  padding: 10px;
  background-color: #ffffff; /* White background for product rows */
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.cartitems-row {
  margin-bottom: 15px;
}

.cartitems-quantity {
  background-color: #f3f4f6; /* Ash-grey background */
  border: 1px solid #ddd;
  color: #6d28d9; /* Purple text */
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: bold;
  cursor: default;
}

.cartitems-remove-icon {
  cursor: pointer;
  color: #d97706; /* Gold */
  transition: color 0.2s ease-in-out;
}

.cartitems-remove-icon:hover {
  color: #b45309; /* Darker gold for hover */
}

.cartitems-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a; /* Neutral text */
}

.cartitems-total p:first-child {
  color: #6d28d9; /* Purple label for total */
}

.cart-empty-message {
  text-align: center;
  margin: 20px 0;
  font-size: 16px;
  color: #6b7280; /* Ash-grey text */
}

/* Product Image */
.carticon-product-icon {
  height: 50px;
  width: 50px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 10px;
  border: 1px solid #e5e7eb;
}

.cartitems-checkout {
  background-color: #6d28d9; /* Purple */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cartitems-checkout:hover {
  background-color: #5b21b6; /* Darker purple for hover */
  transform: translateY(-2px); /* Subtle lift effect */
}

.cartitems-checkout:active {
  background-color: #4c1d95; /* Even darker purple for active state */
  transform: translateY(0); /* Reset lift effect */
}

.cartitems-checkout:focus {
  outline: 2px solid #d97706; /* Gold outline for focus */
  outline-offset: 4px;
}

.cartitems-checkout-container {
  margin: 20px auto;
}

/* Media Queries */
@media screen and (max-width: 992px) {
  .cartitems-format {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .cartitems-format-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px 10px;
  }

  .cartitems-total {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .cartitems-checkout {
    width: 100%;
    padding: 14px;
    font-size: 18px;
  }
}
