#news {
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  justify-content: center;
  text-align: center;
}

.news__content {
  background-image: url("../../assets/sports-tools.webp");
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: 50px;
  background-size: cover; /* Set background size to cover */
  background-repeat: no-repeat;
}

/* .news_img {
  width: 50%;
  height: auto;
  object-fit: contain;
}

.news_img1 {
  width: 100%;
  height: auto;
} */

.news__content__Info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 10rem;
  margin: auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.news__content__Info h3 {
  color: gold;
  font-size: 50px;
  font-weight: 600;
  margin-left: 2rem;
}

.news__content__Info h4 {
  font-size: 30px;
  font-weight: 500;
  color: white;
  display: flex;
  margin-top: 8px;
  margin-left: 2rem;
  font-family: "Atkinson Hyperlegible", sans-serif;
}

.news__content__Info p {
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  margin-top: 8px;
  margin-left: 2rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

@media screen and (max-width: 768px) {
  .news__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-size: 50px;
    margin-top: 0;
  }

  .news_img {
    object-fit: contain;
    width: 100%;
  }

  .news__content__Info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .news__content__Info h3 {
    color: gold;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .news__content__Info p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: rgb(255, 255, 255);
    text-align: justify;
  }
}
