#aboutSection {
  color: black;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.aboutIntro {
  background-color: #ffff;
  padding: 0 10rem 0 10rem;
}

.aboutHead {
  font-size: 1.7rem;
  font-weight: 500;
  font-family: "Recoleta";
  padding: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.aboutHeadHook {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
}

.journeyHead {
  font-family: "Recoleta";
}

.companyDetails {
  text-align: center;
  margin: 2rem auto 0;
  background-color: #faf9f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
}

.journeyHead {
  font-size: 1.8rem;
  font-weight: 600;
  color: #a020f0;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.aboutIntroPara {
  color: rgb(0, 0, 0);
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

.aboutIntroList {
  margin-left: 10rem;
  text-align: justify;
  list-style-type: circle;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 1.1rem;
}

.aboutIntro3 {
  color: rgb(0, 0, 0);
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  padding-top: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

.registrationNote {
  text-align: center;
}

.Pin {
  margin: 2rem auto;
}

.registrationDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem 1rem 0rem;
}

.registrationDetails > h4 {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Recoleta";
  color: #a020f0;
  padding: 1rem;
  text-align: center;
}

.ourConstitutionBtn,
.registrationDetailsBtn {
  background-color: #a020f0;
  color: white;
  margin: 1rem;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 25px;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.ourConstitutionBtn:hover,
.registrationDetailsBtn:hover {
  background-color: gold;
  color: #a020f0;
  cursor: pointer;
}

.ourConstitutionBtn > a:visited,
.registrationDetailsBtn > a:visited {
  color: white;
}

.whoweare__mission__vision_divs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 60%;
  gap: 1rem;
  margin-bottom: 2rem;
}

.companyDetailsHead,
.membershipInfo {
  color: #a020f0;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  margin-top: 1rem;
  font-family: "Recoleta";
  margin-bottom: 0.5rem;
}

.ourMission,
.ourVision {
  color: #a020f0;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Recoleta";
  margin-bottom: 1rem;
}

.mission,
.vision {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-content: center;
  border: 1px solid #ead7f7;
  width: 300px;
  height: 250px;
  border-radius: 15%;
  padding: 10px;
}

.fa.fa-bullseye,
.fa.fa-eye {
  color: #a020f0;
  font-size: 60px;
  margin-bottom: 10px;
}

.mision__vision__header {
  color: #a020f0;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  font-family: "Recoleta";
  margin-bottom: 1rem;
}

.goalsList {
  padding-left: 2rem;
}

#goals {
  background-color: #f3f3f3;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

/* rgb(250, 247, 247); */

.goalsInfoHead {
  margin: 4rem 5rem 1rem 5rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.goalsInfoHead > h2 {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Recoleta";
}

.goalsHead {
  margin-bottom: 2rem;
  font-family: "Recoleta";
  color: #a020f0;
}
.goalsBars {
  margin: 0 5rem 5rem 5rem;
}

.goalsBar {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.goalsBarInfo {
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
}

.goalsBarInfo > h4 {
  font-family: "Recoleta";
  color: #a020f0;
  font-size: 1.3rem;
  text-align: justify;
}

.goalsBarInfo > p {
  font-size: 1rem;
  padding: 0.8rem 0 1rem 0;
  text-align: justify;
}

.goalsBar {
  padding-top: 0.5rem;
}

.numberImg {
  object-fit: contain;
  height: 4rem;
  width: 4rem;
  margin-right: 0.5rem;
}

.memberImg {
  height: 5rem;
  width: 5rem;
  margin-top: 1rem;
  border-radius: 50%;
  object-fit: fill;
}

.membership > h1 {
  text-align: center;
  font-family: "Recoleta";
}

.membership {
  margin: auto;
  max-width: 65rem;
  text-align: center;
  justify-content: center;
}

.membershipList {
  text-align: left;
  list-style-type: circle;
  justify-content: space-between;
}

.membershipList > li {
  margin-bottom: 0.7rem;
}

.management {
  background-color: #ebfaef;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-bottom: 1rem;
}

.management > h2 {
  text-align: center;
  font-family: "Recoleta";
  font-size: xx-large;
  margin-top: 3rem;
  color: #a020f0;
}

.managementBars {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; /* wrap content that exceeds width */
  flex-grow: 1;
  flex-basis: 100px;
  margin: auto;
  margin-top: 2rem;
}

.managementBar {
  margin: 0 3rem auto;
  /* align-items: center; */
  /* justify-content: center; */
  text-align: center;
  width: 25rem;
  box-shadow: 0 4px 8px 0 rgba(222, 221, 221, 0.2);
}

.managementBar > h4 {
  margin-top: 0.5rem;
  font-family: "Recoleta";
  font-size: 1.3rem;
}

.memberTitle {
  margin-top: 0.5rem;
  font-family: "Recoleta";
  font-size: 1.3rem;
  color: darkgrey;
}

.managementBar > p {
  margin-top: 0.5rem;
  /* text-align: justify; */
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta"), url(../../fonts/Recoleta-SemiBold.woff) format("woff");
}

@media screen and (max-width: 1012px) {
  .aboutIntro {
    padding: 0 2rem 0 2rem;
  }

  .aboutIntro > p {
    text-align: justify;
    font-size: 1.1rem;
    padding: 0 1rem 0 1rem;
  }

  .aboutIntroPara > p {
    font-size: 1rem;
    padding: 0rem;
  }

  .registrationDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .registrationInfo {
    text-align: center;
    font-size: 1.3rem;
  }

  .aboutIntro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 1rem;
  }

  .aboutIntroList {
    margin-left: 2rem;
  }

  .aboutHead {
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    margin-bottom: 2rem;
  }

  .membershipList > li {
    padding: 1rem;
  }

  .goalsInfoHead {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .goalsText {
    text-align: left;
    padding: 1rem;
  }

  .goalsBar {
    width: 100%;
    height: auto;
  }

  .goalsBars {
    margin: 0;
    padding: 0;
  }

  .goalsBarInfo {
    margin-right: 1rem;
  }

  .ourVision,
  .ourMission,
  .companyDetailsHead,
  .membershipInfo {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .managementBar {
    margin: 1.5rem auto;
    width: 85%;
    padding: 10px;
  }

  .managementBar > p {
    text-align: justify;
  }
}
