.member__support {
  display: flex;
  flex-direction: column;
  width: 70%;
  color: black;
  margin: 1rem auto;
}

.support__form__intro {
  background-color: rgb(127, 240, 248);
  padding: 2rem 1rem;
  margin: 1rem auto;
  border-left: blue;
}

.support__form {
  padding: 1rem 1rem;
  margin: auto;
}

/* Style for form labels */
.support__label {
  display: block;
  margin-bottom: 0.5rem;
}

/* Style for form input fields */
.support__input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.submit__btnDiv {
  align-items: center;
  margin: 2rem auto;
}

.submit__btn {
  background-color: #e83735;
  display: flex;
  margin: auto;
  color: white;
  padding: 8px;
  border: none;
}

.submit__btnInfo {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .member__support {
    width: 100%;
    margin: auto;
  }
  .support__form {
    width: 100%;
    padding: 0.5rem;
    margin: auto;
  }

  .support__form__intro {
    width: 100%;
    margin: 1rem auto;
    padding: 1rem;
  }
  .submit__btn {
    padding: 4px;
  }
}
