.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 5rem;
  margin: 0 auto;
  padding: 1rem 1rem;
  position: sticky;
  top: 0;
  z-index: 3;
  font-family: "Poppins", sans-serif;
  border-bottom: 0.3px solid #ccc;
  z-index: 1000;
}

.navbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.desktopMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navLogo {
  object-fit: contain;
  height: 5rem;
  width: 5rem;
}

.navbarItems {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.navbarItem {
  margin: 1rem;
  cursor: pointer;
  color: black;
}

.navbarItem.active {
  color: gold;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid gold;
}

/* Styles for the dropdown content */
.dropdown-Content {
  display: none; /* Initially, hide the dropdown */
  position: absolute;
  background-color: #ffffff;
  box-shadow: 3px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 1rem;
}

/* Style the dropdown items */
.dropDown {
  list-style: none; /* Remove list bullet points */
  padding: 0;
  margin: 0;
}

.dropDown li {
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

/* Define the appearance of the links inside the dropdown */
.dropDown li a {
  text-decoration: none;
  color: black; /* Text color for the links */
  display: block;
  text-align: left;
  padding: 12px 16px;
}

/* Change link color on hover */
.dropDown li a:hover {
  background-color: #a020f0;
  color: white;
  list-style-type: circle;
}

/* Make the dropdown visible when hovering over the navbar item */
.navbarItem:hover .dropdown-Content {
  display: block;
}

.navbarRegisterBtn {
  border: 2px solid black;
  border-radius: 25px;
  border-color: #e7e7e7;
  background-color: white;
  color: black;
  padding: 9px 18px;
  font-size: 13px;
  cursor: pointer;
  color: black;
  margin: 1rem 0;
  margin-right: 1rem;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.navbarRegisterBtn:hover {
  border-color: #ffd700;
  color: green;
}

.navbarRegisterBtn > a:visited {
  color: black;
}

.navbarRegisterBtn > a,
.loginBtn > a {
  color: black;
}

.navbarRegisterBtn > a:hover,
.loginBtn > a:hover {
  color: #043f11;
}

.loginBtn {
  border: 2px solid;
  border-radius: 25px;
  border-color: #e7e7e7;
  background-color: white;
  color: black;
  padding: 9px 18px;
  font-size: 13px;
  cursor: pointer;
  margin: 1rem 0;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.loginBtn:hover {
  border-color: #ffd700;
  color: green;
}

.loginBtn > a:visited {
  color: black;
}

.profileIcon {
  object-fit: contain;
  height: 2rem;
  width: 4rem;
  padding-right: 1rem;
}

.navbarItem:hover {
  color: gold;
  padding-bottom: 1rem;
  border-bottom: 3px solid #ffd700;
}

.mobMenu {
  display: none;
  object-fit: cover;
  height: 1.5rem;
  z-index: 1000;
}

.navMenu {
  position: absolute;
  width: 100%;
  top: 5rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  background: rgb(255, 255, 255);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.mobMenuListItem {
  color: rgb(0, 0, 0);
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  background: rgb(255, 255, 255);
}

.navMenu a:hover {
  background: #b0f3ff;
  color: black;
}

@media screen and (max-width: 1012px) {
  .navbarItem {
    margin: 10px;
  }

  .navbar {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 940px) {
  .mobMenu {
    display: flex;
    color: black;
  }

  .buttonsDiv {
    display: none;
  }

  .navLogo {
    object-fit: contain;
    padding-left: 0.3rem;
  }

  .desktopMenu {
    display: none;
  }

  .desktopMenuBtn {
    display: none;
  }

  .navbar {
    padding-left: -1rem;
    height: 5rem;
  }
}
