.profilePage {
  overflow: hidden;
  color: black;
  display: flex;
  flex-direction: row; /* Adjust the layout to a row */
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  margin: 1rem auto;
  padding: 1rem 0rem;
}

input:focus {
  outline: none;
  border: 1px solid #64d488;
}

.memberProfileDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  box-shadow: 3px 4px 8px 3px rgba(0, 0, 0, 0.2);
  margin: auto;
  margin-bottom: 3rem;
  color: black;
}

.member__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%; /* Set the width */
  height: auto; /* Let the height adjust based on content */
  border: 1px solid gold;
  margin-right: 0.5rem; /* Add some margin */
  color: black;
}

.profile__pic {
  vertical-align: middle;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 2px solid rgb(0, 0, 0);
}

.member__pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff; /* white border */
  object-fit: contain;
}

.memberDetailsForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 2rem; /* Adjust padding */
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  margin: 1rem; /* Add some margin */
}

.memberDetailsForm > h4 {
  font-size: 1.3rem;
  padding: 1rem;
  text-align: center;
}

.profileNameSection {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
}

.profileNameSectionPart {
  margin-right: 3rem;
}

.profileFirstname,
.profileSurname,
.profileMiddlename {
  font-size: medium;
  width: 100%;
  max-width: 30rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  margin: 1rem auto 3rem;
}

.profileContactsSection {
  display: flex;
  flex-direction: row;
  margin: 3rem 0 3rem 0;
  justify-content: space-between;
}

.profileEmailPart,
.profilePhoneNumberPart {
  display: flex;
  flex-direction: column;
}

.profileYourEmail,
.profilePhoneNumber {
  width: 20rem;
  font-size: medium;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.profileIdNumber {
  font-size: medium;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

/* Location Div Styling */

.profileLocation {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.profileCityCountyDiv {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
}

.profileCountyDiv,
.profileZipDiv {
  margin-right: 2rem;
}

.profileZipCountryDiv {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
}

.profilePersonalInfo {
  display: flex;
  flex-direction: row;
  margin: 2rem auto;
}

.profilePersonalInfoPart {
  margin-right: 4rem;
}
.profileBirthdate {
  font-size: medium;
  padding: 0.5rem 1rem;
  width: 10rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

/**CSS for profile pic*/

input[type="file"] {
  display: none;
}

.memberCardImage {
  display: flex;
  align-items: center;
}

.image-container {
  display: flex;
  align-items: center;
}

.profilePicUpload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}

.custom-upload-button {
  background: #3fa1a9;
  color: #fff;
  padding: 8px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
}

.custom-upload-button:hover {
  background: #79f1a4;
}

.profilePicUploadDiv img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-upload-before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #63d3a6;
  font-size: 16px;
  cursor: pointer;
}

/**Location Section*/
.profileStreetName {
  font-size: medium;
  width: 100%;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.profileZipCountryDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileCityDiv,
.profileZipDiv {
  display: flex;
  flex-direction: column;
}

.profileCounty,
.profileCity,
.profilePostOffice,
.profileCountry,
.profilePObox {
  font-size: medium;
  width: 100%;
  max-width: 15rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.registrationFormBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.registrationFormBtn {
  background: #060b26;
  color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.7rem 2.5rem;
  margin: 1rem;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 768px) {
  .profilePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .memberProfileDiv {
    width: 100%;
    padding: 0;
    margin: auto;
  }

  .memberDetailsForm {
    width: 100%;
    padding: 0;
    margin: auto;
  }

  .profileNameSection,
  .profilePersonalInfo,
  .profileLocation,
  .profilePicUploadDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin: 1rem auto;
    margin-left: 0.5rem;
  }

  .profileContactsSection {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 1rem auto;
    margin-left: 0.5rem;
  }

  .profileNameSectionPart,
  .profilePhoneNumberPart,
  .profilePersonalInfoPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 85%;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #a020f0;
  }

  .profileEmailPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    font-weight: 500;
    font-size: 1.1rem;
  }
  .profileFirstname,
  .profileSurname,
  .profileMiddlename,
  .profileYourEmail,
  .profilePhoneNumber,
  .profileIdNumber,
  .profileBirthdate,
  .profileStreetName,
  .profileCounty,
  .profileCity,
  .profilePostOffice,
  .profileCountry,
  .profilePObox {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .profilePicUploadDiv img {
    width: 100%;
    height: auto;
  }

  .profileCityCountyDiv,
  .profileZipCountryDiv {
    flex-direction: column;
    color: #a020f0;
  }

  .profileStreetNameDiv {
    color: #a020f0;
  }

  .profileCityDiv,
  .profileZipDiv {
    margin: 0.5rem;
  }

  .profileCountyDiv,
  .profileZipDiv {
    margin: 0.5rem;
  }

  .profileCity,
  .profileCounty,
  .profilePostOffice,
  .profileCountry,
  .profilePObox {
    width: 100%;
    max-width: 100%;
  }

  .registrationFormBtn {
    padding: 0.7rem 1.5rem;
  }
}
