.faq__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.faq__container {
  background-color: #e4c2f9;
  max-width: 1140px;
  margin: 2rem auto;
  color: black;
  padding: 30px; /* Add padding if needed */
}

.faq__head > h1 {
  font-size: 30px;
  color: #a020f0;
  margin: 10px;
  margin-bottom: 15px;
}

.accordion__faq .active {
  display: block;
}

.accordion__faq .inactive {
  display: none;
}

.accordion__faq > div {
  background-color: white;
  margin-bottom: 20px;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
}

.accordion__title {
  color: #c47685;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
}

.accordion__faq-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion__faq-heading .active {
  color: #a020f0;
}

.accordion__faq-heading h3 {
  font-weight: 600;
}

.accordion__para {
  padding: 5px 0;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}
