#registerationPage {
  width: 100%;
  color: black;
  padding: 5rem;
  font-family: "Poppins", sans-serif;
}

.registrationFormDiv {
  box-shadow: 3px 4px 8px 3px rgba(0, 0, 0, 0.2);
}

.registrationRequirements {
  width: 65rem;
  margin: auto;
}

.registrationRequirements > p {
  font-size: 0.9rem;
}

.registrationRequirementsHead {
  color: #a020f0;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  font-family: "Recoleta";
  text-align: center;
  display: flex;
  justify-content: center;
}

.registrationRequirementsList {
  list-style-type: circle;
}

.registrationRequirementsList > p {
  font-size: 1.3rem;
}

.registrationInfo {
  width: 65rem;
  margin: auto;
  font-size: 1rem;
}

.registrationInfoHead {
  margin: 1rem 0 1rem 0;
  font-family: "Recoleta";
  color: #a020f0;
}

.registrationInfoBar {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 1.4rem;
}

.registerFormDiv {
  display: flex;
  justify-content: center;
}

.registrationForm {
  background-color: rgb(255, 255, 255);
  padding: 3rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 65rem; /* Set the width of the form */
}

.registrationForm > h4 {
  font-size: 1.3rem;
  margin: 2rem 0rem 2rem 0rem;
  text-align: center;
  color: #a020f0;
}
.nameHead {
  padding-top: 4rem;
}

.nameSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nameSectionPart > p {
  margin-left: 8px;
}

.nameSectionPart {
  margin-right: 3rem;
}

.firstname,
.surname,
.middlename {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.monthDayPart {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

.contactsSection {
  display: flex;
  flex-direction: row;
  margin: 3rem 0rem 3rem 3rem;
  justify-content: space-between;
  padding-right: 10rem;
  margin: auto;
}

.contactSelectionPart {
  display: flex;
  flex-direction: row;
}

.contact__head {
  padding: 2rem;
}

.areacodeSelection {
  margin-right: 1rem;
}

.emailPhoneInputPart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-left: 30px;
}

.password_requirements {
  font-size: 1.1rem;
  margin: auto;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: -10px;
  color: rgb(255, 41, 41);
}

.passwordPart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-left: 20px;
}

.confirmPassword,
.password {
  width: 300px;
}

.phonenumberSection {
  margin-right: 2rem;
}

.yourEmail,
.PhoneNumber,
.IdNumber,
.confirmPassword,
.password {
  font-size: medium;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.yourEmail {
  width: 500px;
}

.emailPart > h4,
.phonenumberPart > h4 {
  margin-left: 8px;
}

.streetNameDiv {
  display: none;
}

.areaCode {
  font-size: medium;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  width: 7rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.areacodeSelection > p {
  font-size: 1rem;
  margin-right: 2rem;
  text-align: center;
}

.PhoneNumber {
  margin-right: 1rem;
}

/* Location Div Styling */

.Location {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.cityCountyDiv {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
}

.countyDiv,
.zipDiv {
  margin-right: 2rem;
}

.zipCountryDiv {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
}

.boxZipDiv {
  display: flex;
  flex-direction: row;
}

.personalInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.personalInfoPart {
  margin-right: 4rem;
}

.month,
.day,
.year {
  font-size: medium;
  width: 100%;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.streetName {
  font-size: medium;
  width: 100%;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.areacodeSelection {
  display: none;
}

.county,
.city,
.postOffice,
.Country,
.PObox {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.Feedback {
  margin: 2rem 0;
}

/* Feedback Styling */

.Feedback > h4 {
  margin-bottom: 1rem;
}

.feedbackCheckDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.feedbackCheckDiv > p {
  margin-left: 1rem;
}

.commBars {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.commBars > p {
  margin-left: 1rem;
}

.themRules {
  margin: 2rem 0;
}

.rulesList {
  margin-left: 2rem;
}

.userAgreement {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.userAgreement > p {
  margin-left: 1rem;
}

.privacyPart {
  margin: 2rem 0;
}

.privacyPolicyAgreementCheck {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.privacyPolicyAgreementCheck > p {
  margin-left: 1rem;
  padding: 1rem 0;
}

.finalNote {
  background-color: antiquewhite;
  max-width: 65rem;
  text-align: center;
}

.thankyouNote {
  padding: 1rem;
}

.registrationFormBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.registrationFormSubmitBtn {
  background: #a020f0;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.7rem 2.5rem;
  margin: 1rem;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 912px) {
  #registerationPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    padding: 1rem;
  }

  .registerFormDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }

  .registrationForm {
    padding: 1rem;
    width: 90%;
  }

  .registrationRequirements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0;
    width: 100%;
    text-align: left;
  }

  .registrationRequirementsHead {
    font-size: 1.5rem;
    text-align: center;
  }

  .registrationRequirementsList > p {
    font-size: 1rem;
    margin-left: -2rem;
    width: 90%;
  }

  .registrationInfo {
    width: 100%;
  }

  .nameSection {
    display: flex;
    justify-content: center;
  }

  .nameSectionPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  .nameSectionPart {
    margin-right: 0;
  }

  .nameSectionPart > p {
    margin-left: 0.7rem;
  }

  .nameSection > input {
    width: 100%;
  }

  .firstname,
  .surname,
  .middlename,
  .IdNumber,
  .yourEmail,
  .PhoneNumber,
  .city {
    max-width: 100%;
    padding: 0;
    height: 40px;
    width: 90%;
  }

  .IdNumber {
    width: 100%;
  }

  .personalInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .personalInfoPart {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    margin-right: 1rem;
  }

  .personalInfoPart > p {
    margin-left: 10px;
  }

  .IdPart {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    margin-right: 1rem;
  }

  .IdPart > p,
  .cityDiv > p {
    margin-left: 10px;
  }

  .emailPhoneInputPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }

  .contactsSection {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin: 0;
    padding: 0;
  }

  .contactSelectionPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .phonenumberSection {
    margin-top: -2rem;
    width: 100%;
  }

  .emailPart,
  .phoneNumberPart {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    margin-right: 0;
    width: 100%;
    margin-right: 1rem;
  }

  .emailPart > h4,
  .phoneNumberPart > h4 {
    margin-left: 10px;
  }

  .contact__head {
    padding: 0;
  }

  .areacodeSelection {
    margin-right: 0;
  }

  .phonenumberSection {
    margin-right: 0;
  }

  .Location,
  .zipCountryDiv {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .boxZipDiv {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    text-align: left;
  }

  .zipDiv > label {
    margin-left: 10px;
  }

  .cityDiv {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
  }

  .cityCountyDiv {
    text-align: left;
  }

  .countyDiv > p,
  .countryDiv {
    margin-left: 10px;
  }

  .passwordPart {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    margin-left: 0;
    width: 90%;
    gap: 10px;
  }

  .firstPasswordPart,
  .confirmPasswordPart {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    margin-left: 0;
    width: 90%;
  }

  .confirmPassword,
  .password {
    padding: 0;
    height: 40px;
    width: 90%;
  }

  .rulesList {
    text-align: left;
  }

  .privacyPolicyAgreement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    margin: 0;
    padding: 0;
  }

  .privacyPolicyAgreementCheck,
  .userAgreement {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: justify;
    margin: 0;
    padding: 0;
    flex-wrap: nowrap;
  }

  .privacyPolicyAgreementCheck input[type="checkbox"],
  .userAgreement input[type="checkbox"] {
    width: 3rem;
    height: 3rem;
    accent-color: green;
  }
}

@media screen and (max-width: 480px) {
  .nameSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
