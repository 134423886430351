.related_item {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-self: center;
  gap: 10px;
}

.related_item p {
  margin: 6px 0px;
}

.related_item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
}

.related_item_name_price {
  color: #374151;
  font-size: 18px;
  font-weight: 600;
}

/* .item_price_old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
  } */

.related_item:hover {
  transform: scale(1.05);
  transition: 0.6s;
}

@media screen and (max-width: 768px) {
  .related_item {
    width: 100%;
    gap: 0px;
  }

  .item_name_price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .item_prices {
    margin: 30px;
  }
}
