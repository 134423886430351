.whoweare {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: black;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: scroll;
}

.whoweare__intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  text-align: center;
  padding: 1rem 0rem 2rem 0rem;
  margin: auto;
}

.whoweare__intro > h4 {
  font-size: 45px;
  padding: 2rem 0 2rem 0;
  font-weight: 600;
  /* font-family: "Recoleta"; */
}

.whoweare__introBtn {
  display: flex;
  background-color: #ffffff;
  color: black; /* Set default text color */
  width: 250px;
  padding: 1rem 1.5rem;
  border: 2px solid black;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin: 2rem auto;
}

.whoweare__introBtn a {
  color: inherit; /* Inherit text color from parent */
  text-decoration: none; /* Remove default underline */
}

.whoweare__introBtn:hover {
  background-color: #7d0dc3;
  color: #ffffff; /* Change text color on hover */
}

.whoweare__vision,
.whoweare__mission {
  width: 65rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding: 3rem 0rem 3rem 0rem;
  margin: auto;
  margin-bottom: 2rem;
}

.whoweare__visionImgDiv {
  width: 50%;
  padding: 1rem;
}

.whoweare__missionTxt > h4,
.whoweare__visionTxt > h4 {
  font-size: 1.8rem;
  padding: 2rem;
  color: #7d0dc3;
  /* font-family: "Recoleta"; */
}

.whoweare__visionH4 {
  display: none;
  font-size: 1.8rem;
  padding: 2rem;
  color: #7d0dc3;
}

.whoweare__visionH4 {
  text-align: center;
  margin: auto;
}

.whoweare__missionTxt,
.whoweare__visionTxt {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.whoweare__missionTxt > span,
.whoweare__visionTxt > span {
  font-size: 1.2rem;
  margin: 0rem auto;
  /* width: 50%; */
}

.whoweare__missionTxt > p,
.whoweare__visionTxt > p {
  margin: 2rem auto;
  text-align: justify;
  font-size: 1rem;
}

.whoweare__visionImg {
  display: flex;
  object-fit: contain;
  height: 420px;
  margin-right: 2rem;
  padding: 1rem;
}

.whoweare__missionImgDiv {
  width: 50%;
  margin: auto;
}

.whoweare__missionImg {
  display: flex;
  object-fit: contain;
  height: 630px;
  margin-left: 3rem;
}

.whoweare__visionBtn,
.whoweare__missionBtn {
  border: none;
  border-radius: 25px;
  background-color: #7d0dc3;
  color: rgb(255, 255, 255);
  padding: 0.7rem 1.3rem;
  font-size: 13px;
  cursor: pointer;
  margin: 2rem 0;
  align-items: center;
  font-family: "Recoleta", sans-serif;
  font-size: medium;
  margin-right: 3rem;
  margin-left: 2rem;
}

.whoweare__visionBtn:hover,
.whoweare__missionBtn:hover {
  border: #ffd700 1px solid;
  padding: 0.7rem 1.3rem;
}

.whoweare__missionBtn > a,
.whoweare__visionBtn > a {
  color: white; /* Set link text color */
  text-decoration: none; /* Remove default underline */
}

.whoweare__missionBtn > a:hover,
.whoweare__visionBtn > a:hover {
  color: #7d0dc3; /* Change link text color on hover */
}

.whoweare__missionBtn:hover,
.whoweare__visionBtn:hover {
  background-color: white;
  color: #ffffff; /* Change text color on hover */
  cursor: pointer;
}

.goals {
  background-color: #fffff0;
  width: 100vw;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

.ourGoals {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.ourGoalsHead {
  color: #7d0dc3;
  text-align: center;
  font-size: 1.7rem;
  /* font-family: "Recoleta"; */
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.goalsBtnDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goalsBtn {
  background-color: #7d0dc3;
  color: black;
  margin: 1rem;
  padding: 0.7rem 1.3rem;
  border: none;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: medium;
}

.goalsBtn > a {
  color: white;
  text-decoration: none;
}

.goalsBtn > a:visited {
  color: white;
}

.objectiveBars {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* wrap content that exceeds width */
  flex-grow: 1;
  flex-basis: 100px;
  margin-top: 2rem;
  text-align: left;
  margin: 0rem auto;
}

.objectiveBar {
  display: flex;
  flex-direction: column;
  background-color: #7d0dc3;
  color: #fcfbf9;
  /* font-family: "Recoleta"; */
  font-size: 1.2rem;
  padding: 2rem 2rem;
  margin: 1rem 1rem;
  object-fit: cover;
  height: 10rem;
  width: 20rem;
  border-radius: 15px;
}
.objectiveBar > i {
  font-size: 2rem;
}

@media screen and (max-width: 912px) {
  .whoweare__intro {
    width: 100%;
    padding: 1rem;
  }

  .whoweare__intro > h4 {
    font-size: 1.8rem;
    padding: 1rem 0 1rem 0;
    font-weight: 600;
    font-size: 40px;
  }

  .whoweare__intro > p {
    text-align: justify;
  }

  .whoweare__missionTxt > h4,
  .whoweare__visionTxt > h4 {
    font-size: 1.5rem;
  }

  .whoweare__missionTxt > h4 {
    margin-top: -3rem;
  }

  .whoweare__vision,
  .whoweare__mission,
  .goals {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .whoweare__vision,
  .whoweare__mission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0rem;
    margin: auto;
    margin-bottom: 2rem;
  }

  .whoweare__visionTxt > span,
  .whoweare__missionTxt > span {
    font-size: 1rem;
  }

  .objectiveBars {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .whoweare__visionImg {
    width: 100%;
    height: auto;
    padding: 0rem;
  }

  .whoweare__missionImg {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .whoweare__missionTxt,
  .whoweare__visionTxt {
    width: 100%;
  }

  .whoweare__visionImgDiv {
    width: 100%;
    padding: 0rem;
  }

  .whoweare__missionTxt > h4,
  .whoweare__visionTxt > h4 {
    font-size: 1.5rem;
    padding: 1rem;
    color: #7d0dc3;
  }

  .whoweare__missionTxt > span,
  .whoweare__visionTxt > span {
    font-size: 1rem;
  }

  .whoweare__missionTxt > p,
  .whoweare__visionTxt > p {
    font-size: 1rem;
    text-align: justify;
    margin: auto;
    padding: 0.6rem;
  }

  .whoweare__visionTxt > h4 {
    display: none;
  }

  .whoweare__visionH4 {
    display: block;
  }

  .whoweare__visionBtn,
  .whoweare__missionBtn {
    font-size: 0.8rem;
  }

  .whoweare__missionImgDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
  }

  .ourGoals {
    width: 100%;
  }

  .goalsList {
    width: 90%;
    text-align: left;
  }

  .ourGoalsListTxt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .ourGoalsListTxt > p {
    font-size: 1.1rem;
    text-align: justify;
  }

  .goalsBtn {
    font-size: small;
  }

  .objectiveBar {
    font-size: 1rem;
    padding: 1rem 2rem;
    height: 8rem;
    width: 15rem;
  }
}
