.snipet {
  max-width: 65rem;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem auto;
}

.insight_div {
  width: 50%;
}

.insight_div h2 {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
  color: #f86464;
  padding: 1rem 0;
}

.insight_div h3 {
  font-family: "Oswald", sans-serif;
  font-size: 35px;
  font-weight: 400;
  padding-bottom: 0.7rem;
}

.insight_div p {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 400;
  font-style: normal;
  size: 18px;
  line-height: 35px;
}

.insight_div a {
  text-align: center;
  align-items: center;
}

.articles_div {
  width: 50%;
  text-align: justify;
}

.articles_div h3 {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 400;
  font-style: normal;
  size: 18px;
  line-height: 35px;
}

.new_blog_articles {
  padding: 1rem 0;
}

.new_blog_articles h4 {
  font-family: "Oswald", sans-serif;
  font-size: 32px;
  font-weight: 400;
  padding: 0.7rem 0;
}

.new_blog_articles a {
  color: #a020f0;
  /* padding: 2rem 0; */
  text-decoration: underline;
}

.new_blog_articles p {
  font-size: 400;
}

.notifyLaunchDiv {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.notifyLaunchDiv p {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
  color: #f86464;
}

.notify__email {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  color: black;
  border: solid 1px black;
  background: #e8f0fe;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 720px) {
  .snipet {
    flex-direction: column;
    padding: 0 1rem;
  }

  .insight_div {
    width: 100%;
  }

  .articles_div {
    width: 100%;
    padding-top: 1rem;
  }
}
