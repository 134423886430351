/* addadmin.css */

/* Style for the outer container */
.addAdmin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

/* Style for the main container */
.addAdminDiv {
  padding: 3rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 50%; /* Set the width of the form */
}

/* Style for the heading */
.addAdminDiv > h4 {
  margin: 1rem auto;
  text-align: center;
  font-size: 1.5rem;
  font-family: Recoleta;
  padding: 1rem;
}

/* Style for the form */
.addAdminForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

/* Style for each form field */
.formField {
  margin-bottom: 1.5rem;
}

/* Style for form labels */
.inputLabel {
  display: block;
  margin-bottom: 0.5rem;
}

/* Style for form input fields */
.inputField {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style for the submit button */
.addAdminBtn {
  background-color: #007bff; /* Blue color for the button */
  color: #fff; /* Text color for the button */
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Additional styling for responsiveness */
@media (max-width: 576px) {
  .addAdminDiv {
    width: 100%; /* Make the form full width on small screens */
  }
}
