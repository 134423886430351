#addmemberPage {
  max-width: 65rem;
  padding: 0 2rem;
}

.addmemberForm {
  max-width: 65rem;
  border: none;
  padding: 0 2rem;
  margin: 0 3rem;
}

.memberCityCountyDiv,
.memberZipCountryDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem auto;
}

.memberCountyDiv,
.memberZipDiv {
  margin-right: 3rem;
}

.memberStreetNameDiv {
  margin: 1rem auto;
}

.memberPersonalInfo {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: center;
}
