.descriptionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 120px 100px;
  color: black;
}

.descriptionboxNavigator {
  display: flex;
}

.descriptionboxNavBox {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 171px;
  height: 70px;
  border: 1px solid #d0d0d0;
}

.descriptionboxNavBox.fade {
  background: #fbfbfb;
  color: #555;
}

.desriptionboxDescription {
  display: flex;
  flex-direction: column;
  gap: 25px;
  border: 1px solid #d0d0d0;
  padding: 48px;
  padding-bottom: 70px;
}


@media screen and (max-width: 768px) {
  .descriptionBox {
    margin: 10px 20px;
  }
  .descriptionboxNavBox {
    width: 100%;
  }
  .desriptionboxDescription {
    padding: 20px;
    padding-bottom: 40px;
  }
  
}