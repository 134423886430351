.sendemail {
  display: flex;
  flex-direction: column;
  width: 80%;
  color: black;
  margin: 2rem auto;
}

.email__formDiv {
  border: 4rem;
}

.email__form {
  padding: 2rem 4rem;
  margin: auto;
}

.email__label {
  display: block;
  margin-bottom: 0.5rem;
}

.email__subject,
.email__content {
  display: block;
  margin-bottom: 0.5rem;
}

.subject__input,
.content__input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.email__btnDiv {
  align-items: center;
  margin: 2rem auto;
}

.emailSend__btn {
  background-color: #054d29;
  display: flex;
  margin: auto;
  color: white;
  border: none;
}

.email__btnInfo {
  display: flex;
  align-items: center;
}
