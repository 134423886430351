#ourBlog {
  display: flex;
  flex-direction: column;
  color: black;
  width: 65%;
  margin: 0 auto;
}

.ourBlog {
  justify-content: center;
}

.ourBlogHead {
  font-family: "Recoleta";
  text-align: center;
  margin: 1rem auto;
  font-size: 2.5rem;
}

.ourBlogPara {
  text-align: center;
  justify-content: center;
  margin: 2rem auto;
  font-size: 1.3rem;
}

.ourBlogImg {
  width: 100%;
  height: 30rem;
  object-fit: contain;
}

.blogPara {
  text-align: justify;
  font-weight: 400;
}

.blogHeader > h2 {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  margin: 1rem auto;
  font-family: "Recoleta";
}

.benefitsContent {
  margin: 2rem auto;
}

.mainHealthHead {
  font-family: "Recoleta";
  margin: 0.6rem;
}

.benefitsContentPara {
  text-align: left;
  margin: 1rem auto;
}

.healthBenefitsList {
  list-style-type: lower-roman;
  list-style-position: outside;
}

.mentalHealthList {
  list-style-type: circle;
  text-align: left;
  padding: 1rem;
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta"), url(../../fonts/Recoleta-SemiBold.woff) format("woff");
}

@media screen and (max-width: 912px) {
  #ourBlog {
    display: flex;
    flex-direction: column;
    color: black;
    width: 90%;
    margin: 0 auto;
    padding: 10px;
  }

  .ourBlog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .ourBlogHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 0;
  }
  .ourBlogHead {
    font-size: 2rem;
  }
  .ourBlogPara {
    font-size: 1.2rem;
    margin: 1rem auto;
  }

  .ourBlogImg {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }

  .blogContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 0rem;
    text-align: left;
  }

  .healthBlogIntro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  .benefitsContent {
    max-width: 100%;
  }
  .blogHeader > h2 {
    font-size: 1.8rem;
  }
  .benefitsContentPara {
    font-size: 1rem;
  }
  .mainHealthHead {
    font-size: 1.5rem;
  }
  .blogPara {
    font-size: 1rem;
  }

  @media screen and (max-width: 480px) {
    .ourBlog {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 20px;
    }

    .ourBlogHeader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin: auto;
      align-items: center;
      padding: 0;
    }
    .ourBlogHead {
      font-size: 1.5rem;
    }
    .ourBlogPara {
      font-size: 1rem;
    }

    .ourBlogImg {
      width: 100%;
      height: auto;
      margin-bottom: 2rem;
    }

    .blogContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: auto;
      padding: 0rem;
      text-align: left;
    }

    .healthBlogIntro {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
    }

    .benefitsContent {
      max-width: 100%;
    }
    .blogHeader > h2 {
      font-size: 1.5rem;
    }
    .benefitsContentPara {
      font-size: 1rem;
    }
    .mainHealthHead {
      font-size: 1.5rem;
    }
    .blogPara {
      font-size: 1rem;
    }
  }
}
