.item {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-self: center;
  gap: 10px;
  height: auto;
}

.item p {
  margin: 6px 0px;
}

.item img {
  width: 100%;
  height: 200px; /* Adjust this value as needed */
  object-fit: cover; /* Crop to fill the container while maintaining aspect ratio */
  border-radius: 10px; /* Optional: Add rounded corners */
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for aesthetics */
}

.item_prices {
  display: flex;
  gap: 20px;
}

.item_price {
  color: #374151;
  font-size: 18px;
  font-weight: 600;
}

/* .item_price_old {
  color: #8c8c8c;
  font-size: 18px;
  font-weight: 500;
  text-decoration: line-through;
} */

.item:hover {
  transform: scale(1.05);
  transition: 0.6s;
}

.view_options_button {
  padding: 15px 30px;
  width: 170px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background: #a020f0;
  margin-bottom: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .item {
    width: 100%;
    gap: 0px;
  }

  .item_name_price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .item_prices {
    margin: 30px;
  }

  .item > a > img {
    width: 100%;
  }
}
