.constitution {
  color: black;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.constitutionIntro {
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 3rem;
  width: 65%;
  font-family: "Poppins", sans-serif;
  margin: 2rem auto;
}

.constitutionDetails {
  width: 65%;
  background-color: #ffff;
  margin: 2rem auto;
}

.constitutionDetails > h2 {
  text-align: center;
  margin: auto;
  padding: 2rem;
}

.constitutionList {
  text-align: justify;
}

.majorListItem {
  padding: 0.8rem 0rem;
}

.majorListItem > h4 {
  padding: 0.3rem;
  font-size: large;
}

.innerList {
  list-style-type: lower-roman;
  padding: 0.5rem 1rem;
}

.innerCommitteeList {
  list-style-type: upper-roman;
}

.innerCommitteeItem {
  padding: 0.3rem;
}

.innerInnerCommitteeList {
  list-style-type: lower-roman;
  margin-left: 3rem;
}

.committeeList {
  list-style-type: lower-alpha;
  padding-left: 2rem;
}

@media screen and (max-width: 768px) {
  .constitutionIntro {
    width: 90%;
    padding: 1rem;
  }

  .constitutionDetails {
    width: 90%;
  }
}
