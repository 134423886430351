#changePassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center items horizontally */
  color: rgb(0, 0, 0);
}

.reset_container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center;
  width: 450px;
  height: 420px;
  margin: 3rem auto;
  background: #fff;
  padding-bottom: 30px;
  box-shadow: 3px 4px 8px 3px rgba(0, 0, 0, 0.2);
}

.resetImageDiv {
  margin: 0.6rem auto;
}

.resetImage {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
}

.reset__emailIntro {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sideline {
  width: 31px;
  height: 1px;
  background: #383838;
  border-radius: 9px;
}

.reset__emailPara {
  font-size: 0.9rem;
  color: #383838;
  margin: 0rem 3px;
}

.reset__Input {
  display: flex;
  align-items: center;
  margin: auto;
  width: 370px;
  height: 50px;
  background: #eaeaea;
  border-radius: 6px;
}

.reset__Input img {
  margin: 0px 30px;
  align-self: center;
}

.reset__Input input {
  height: 30px;
  width: 200px;
  background: transparent;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  margin: 0px 7px;
}

.password__reset-email {
  margin-bottom: 1rem; /* Add some space below the email input */
}

.reset__passwordBtn {
  background-color: #fe4251; /* Set button color */
  color: #fff; /* Set text color */
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}

.reset__Text {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  margin: 1rem auto;
  font-size: 1rem;
}
