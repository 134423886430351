.termsOfUse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  color: black;
  align-items: center;
  margin: auto;
}

.general h2 {
  color: rgb(0, 0, 0);
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  padding-top: 1rem;
}

.exclusionsDisclaimers > p,
.thirdPartyContent > p,
.uploadingContent > p,
.linkingToWebsite > p,
.unacceptableContent > p,
.contentUsage > p,
.accessWebsite > p,
.acceptanceAgreement > p,
.general p {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(2, 0, 0);
  text-align: justify;
  margin-top: 1rem;
}

.exclusionsDisclaimers > p {
  margin-bottom: 3rem;
}

.acceptanceAgreement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  align-items: center;
  margin: 1rem auto;
}

.acceptanceAgreement > h2 {
  color: rgb(0, 0, 0);
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem;
}

.exclusionsDisclaimers > h3,
.thirdPartyContent > h3,
.uploadingContent > h3,
.linkingToWebsite > h3,
.unacceptableContent > h3,
.accessWebsite > h3,
.contentUsage > h3,
.changesAgreement > h3 {
  color: rgb(0, 0, 0);
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem;
}

.changesAgreement > p {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(2, 0, 0);
  text-align: justify;
  margin-top: 1rem;
}

.contentUsage ul {
  list-style: none;
  padding-left: 1rem;
  gap: 5px;
}

.uploadingContent ul {
  list-style: lower-alpha;
  padding-left: 3rem;
  gap: 5px;
}

@media screen and (max-width: 912px) {
  .termsOfUse {
    width: 90%;
  }

  .general h2 {
    font-size: 2rem;
  }

  .exclusionsDisclaimers > p,
  .thirdPartyContent > p,
  .uploadingContent > p,
  .linkingToWebsite > p,
  .unacceptableContent > p,
  .contentUsage > p,
  .accessWebsite > p,
  .acceptanceAgreement > p,
  .general p {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .exclusionsDisclaimers > h3,
  .thirdPartyContent > h3,
  .uploadingContent > h3,
  .linkingToWebsite > h3,
  .unacceptableContent > h3,
  .accessWebsite > h3,
  .contentUsage > h3,
  .changesAgreement > h3 {
    font-size: 1.5rem;
    padding: 0.5rem;
  }

  .changesAgreement > p {
    font-size: 1rem;
  }

  .contentUsage ul {
    padding-left: 0.5rem;
  }

  .uploadingContent ul {
    padding-left: 2rem;
  }
  
}
