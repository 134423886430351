.shop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  margin: auto;
  margin-bottom: 2rem;
}

.shopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.cart_stuff {
  display: flex;
  flex-direction: row;
}

.cart_count {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-left: -5px;
  border-radius: 11px;
  font-size: 14px;
  background: red;
  color: white;
}

.shop_items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /*   height: 90vh; */
}

.shop_items h1 {
  color: #171717;
  font-size: 30px;
  font-weight: 600;
}

.shop_items hr {
  width: 200px;
  height: 6px;
  border-radius: 10px;
  background: #252525;
}

.shop_items-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Align items horizontally */
  margin-top: 50px;
  gap: 30px;
}

.coming_soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.comming_soon-img {
  height: auto;
  width: 70%;
  object-fit: contain;
}

@media (max-width: 912px) {
  .shop {
    width: 100%;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 0px;
  }

  .shop_items-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: auto;
    gap: 0px;
  }

  .item {
    object-fit: contain;
  }
}
