.productdisplay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 75%;
  margin: 0px auto;
  color: black;
}

.productdisplay-left {
  display: flex;
  gap: 17px;
}

.productdisplay-img-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.productdisplay-img-list img {
  height: 163px;
}

.productdisplay-main-img {
  width: 500px;
  height: 500px;
}

.product-display-right {
  margin: 0px 70px;
  display: flex;
  flex-direction: column;
}

.product-display-right h1 {
  color: #3d3d3d;
  font-size: 40px;
  font-weight: 700;
}

.product-display-right-star {
  display: flex;
  align-items: center;
  margin-top: 13px;
  gap: 5px;
  font-size: 16px;
}

.product-display-right-price {
  display: flex;
  margin: 40px 0px;
  gap: 30px;
  font-size: 24px;
  font-weight: 700;
}

.product-display-right-price-new {
  color: #ff4141;
}

.product-display-right-description {
  color: black;
}

.product-display-right-size h1 {
  margin-top: 55px;
  color: #656565;
  font-size: 20px;
  font-weight: 600;
}

.product-display-right-sizes {
  display: flex;
  margin: 30px 0px;
  gap: 20px;
  /* width: 100%; */
}

.product-display-right-sizes div {
  padding: 18px 24px;
  background: #fbfbfb;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  cursor: pointer;
}

.product-display-right button {
  padding: 20px 40px;
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background: #ff4141;
  margin-bottom: 40px;
  border: none;
  outline: none;
  cursor: pointer;
}

.product-display-right-tag {
  margin-top: 10px;
  margin-bottom: 2rem;
}

.product-display-right-tag span {
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .productdisplay {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    color: black;
  }

  .productdisplay-left {
    margin: 0px auto;
  }

  .product-display-right {
    margin: 0px auto;
  }

  .product-display-right h1 {
    font-size: 30px;
  }

  .product-display-right-star {
    margin-top: 10px;
  }

  .product-display-right-price {
    margin: 20px 0px;
  }

  .product-display-right-size h1 {
    margin-top: 30px;
  }

  .product-display-right-sizes {
    margin: 20px 0px;
    gap: 10px;
  }

  .product-display-right button {
    margin-bottom: 20px;
  }

  .productdisplay-img-list {
    display: none;
    flex-direction: column;
    gap: 0;
  }

  .productdisplay-img-list img,
  .productdisplay-main-img {
    height: auto;
    width: 90%;
  }
}
