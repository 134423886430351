.sidebarContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.adminInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
}

.sidebarContent .sidebar {
  background-color: #2f4050;
  width: 250px;
  height: 100%;
}

.sidebarList {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.sidebarList .sidebarListItem {
  width: 100%;
  height: 60px;
  list-style-type: none;
  border: 1rem;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
}

.sidebarListItem a {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  padding-left: 10px; /* Adjust as needed for icon padding */
}

.sidebarListItem #sidebarIcon {
  flex: 30%;
  display: grid;
  place-items: center;
  color: rgb(255, 255, 255);
}

.sidebarListItem #sidebarTitle {
  flex: 70%;
  color: #ffff;
  display: grid;
}

.sidebarList .sidebarListItem:hover {
  cursor: pointer;
  background-color: #293846;
}

.sidebarListItem:active {
  background-color: #0e2030;
  color: white;
}

.dashboard__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  color: black;
}

.dashboard__header {
  display: flex;
  color: rgb(0, 0, 0);
  align-items: center;
  margin: 1rem auto;
  justify-content: center;
  text-align: center;
  box-shadow: #293846;
}

.adminIcon {
  align-items: right;
  height: 4rem;
  width: 4rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.sidebarListItemBtn {
  background-color: #2f4050;
  width: 100%;
  height: 60px;
  list-style-type: none;
  border: 1rem;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
}
