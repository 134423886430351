/* Reset.css */

#resetPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.reset_container {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.changePass__Inputs {
  display: flex;
  flex-direction: column;
}

.changePass__Input {
  margin-bottom: 15px;
}

.input__parr p {
  margin: 6px 0;
  color: #000000;
}

.input__part {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.new_password,
.confirm_password {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
}

.reset__passwordBtn {
  background-color: #4285f4;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.resetRedirects {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.goLogin a,
.goHome a {
  text-decoration: none;
  color: #333;
}

.goLogin a:hover,
.goHome a:hover {
  text-decoration: underline;
  color: #4285f4;
}

/* Add a line beneath the link */
.goLogin a:after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background: #4285f4;
  transition: width 0.3s;
}

.goLogin a:hover:after {
  width: 100%;
}
