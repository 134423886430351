#manage {
  display: flex;
  font-family: "Poppins", sans-serif;
  overflow: auto;
}

.manage__content {
  color: black;
  font-family: "Poppins", sans-serif;
}

.manage__list {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: center;
  margin: 2rem auto;
  align-items: center;
}

.manageListItem {
  margin: 1rem;
  background-color: #2f4050;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  padding: 1rem 1rem;
  margin: 2rem;
  object-fit: cover;
  height: 3rem;
  margin: 1rem;
  width: 16rem;
  align-items: center;
  text-align: center;
}

.tableDiv {
  padding: 1rem;
  text-align: center;
  overflow-y: scroll;
}

.membersTableHeader {
  font-size: 1.4rem;
  font-family: "Recoleta";
  margin: 1rem auto;
  text-align: center;
}

.tableHeader {
  color: "gold";
  font-size: "1.5rem";
  text-align: "center";
}

.tableBody > tr {
  text-align: left;
}

.memberTable > th {
  text-align: center;
}
